import Profile from "./components/Profile";
import Page from "./components/Page";
import Social from "./components/Social";

function App() {
  const sites = [
    {
      title: "EPica 2 - Spotify",
      link: "https://open.spotify.com/intl-pt/album/4T3LzTlRz61Pak52Bo1qm1?si=AF617uraSxi19ED412iZXw",
      icon: "https://www.svgrepo.com/show/512899/spotify-162.svg",
    },{
      title: "EPica 2 - Youtube",
      link: "https://www.youtube.com/playlist?list=OLAK5uy_midV9Q17ldDZ6COskCAiVFdfKc39QIUx8",
      icon: "https://www.svgrepo.com/show/512899/spotify-162.svg",
    },
    {
      title: "Instagram @Danny.Bond",
      link: "https://www.instagram.com/danny.bond/",
      icon: "https://www.svgrepo.com/show/503411/linux.svg",
    }
  ];
  const sitePages = sites.map((site, i) => (
    <Page title={site.title} link={site.link} icon={site.icon} key={i} />
  ));

  const social = [
    {
      link: "https://www.youtube.com/channel/UCtaOUF4n4oNxWSzrtThBSJA",
      icon: "/1384060.png",
    },
    {
      link: "https://x.com/dannybondd",
      icon: "https://www.svgrepo.com/show/513008/twitter-154.svg",
    },
  ];

  const socials = social.map((social) => (
    <Social link={social.link} icon={social.icon} />
  ));



  return (
    <div className='bg-neutral-100 selection:bg-neutral-800 selection:text-neutral-50 flex justify-center'>
      <div className='w-full max-w-lg h-full min-h-[100dvh] flex flex-col'>
        <Profile />
       
        {sitePages}
        <div className='flex m-12 gap-3' id="socials">{socials}</div>
      </div>
      
     
      
      <div id='capa'></div>
    </div>
    
  );
}

export default App;
