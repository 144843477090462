

const Profile = () => {



  return (
    <div className='flex justify-center items-center flex-col p-3 '>
      <img
        src='/logo-danny.png'
        alt='my github profile'
        id="logodanny"
      />
      <h1>Danny Bondy - Épika 2</h1>
      
    </div>
  );
};

export default Profile;
